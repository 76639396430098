.partners {
  h2 {
    margin-top: 30px;
    color: $color3;
    text-align: center;
    font-weight: 600;
    font-size: 37px;
    @media (max-width: 480px) {
      font-size: 30px;
    }
  }

  ul {
    margin: -10px auto 40px;
    max-width: 940px;
    list-style-type: none;
    text-align: center;
  }

  li {
    display: inline-block;
    margin: 40px 14px 0;
    width: 120px;
    height: 80px;
    line-height: 80px;

    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }
}