.seo {
  overflow: hidden;
  background-color: $color3;
  color: #ffffff;

  h2 {
    @extend .offer-title;
  }

  .items {
    margin-top: 83px;
    text-align: center;
    @media (max-width: 991px) {
      margin-top: 60px;
    }
  }
  .item {
    position: relative;
    display: inline-block;
    width: 200px;
    vertical-align: top;
    cursor: pointer;
    @media (max-width: 991px) {
      width: 120px;
    }
    @media (max-width: 700px) {
      width: 40%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }

    i {
      @extend .offer-icon;
    }
    &:hover i:after {
      filter: drop-shadow(8px 8px 8px rgba(0,0,0,0.43)); 
    }
    &:hover i {
      top: -5px;
      left: -5px;
    }

    & i.seo-audit:after {
      background-image: url('../../static/images/seo-audit.svg');
    }
    &:hover i.seo-audit:after {
      background-image: url('../../static/images/seo-audit--hover.svg');
    }

    & i.seo-competitor:after {
      background-image: url('../../static/images/seo-competitor.svg');
    }
    &:hover i.seo-competitor:after {
      background-image: url('../../static/images/seo-competitor--hover.svg');
    }

    & i.seo-site:after {
      background-image: url('../../static/images/seo-site.svg');
    }
    &:hover i.seo-site:after {
      background-image: url('../../static/images/seo-site--hover.svg');
    }

    span {
      @extend .offer-icon-title;
    }
  }

  .item + .item {
    margin-left: 60px;
    @media (max-width: 700px) {
      margin-left: 0;
    }
  }

  p {
    @extend .offer-text;
  }

  .buttons {
    margin: 30px auto 40px;
    text-align: center;
  }
}