@import "base/all";

$color1: #e24e19;
$color2: #50bccc;
$color3: #54828b;
$color4: #258fa6;
$color5: #e5f5f7;

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Sarabun';
}
.button-1, .button-2 {
  display: inline-block;
  width: 260px;
  height: 64px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 60px;
  @media (max-width: 768px) {
    display: block;
    margin: 0 auto;
  }
}

.button-1 {
  background-color: $color1;
}
.button-2 {
  background-color: $color2;
}
.second-button {
  margin-left: 30px;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-left: auto;
  }
}

.offer-title {
  margin-top: 46px;
  text-align: center;
  font-weight: 600;
  font-size: 37px;
  @media (max-width: 700px) {
    margin-top: 40px;
    font-size: 32px;
  }
}
.offer-icon {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100px;
  @media (max-width: 700px) {
    padding-top: 60%;
    height: auto;
  }
  @media (max-width: 480px) {
    padding-top: 50%;
  }

  &:after {
    @include Pseudo;
    @include Image;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.offer-icon-title {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  font-size: 25px;
  @media (max-width: 991px) {
    font-size: 18px;
  }
  @media (max-width: 700px) {
    margin-bottom: 40px;
    font-size: 24px;
  }
}

.offer-text {
  margin: 65px auto 0;
  max-width: 615px;
  text-align: justify;
  font-size: 16px;
  @media (max-width: 700px) {
    margin: 30px auto 0;
  }
}