@mixin Pseudo {
  display: block;
  content: '';
}

@mixin ClearFix {
  &:after {
    @include Pseudo;
    clear: both;
  }
}

@mixin Image() {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}