.feedback {
  overflow: hidden;

  .content {
    display: table;
    margin: 37px auto 53px;
  }

  .form-container {
    float: left;
    @media (max-width: 767px) {
      float: none;
      text-align: center;
    }
    h2 {
      margin-bottom: 40px;
      color: $color1;
      font-weight: 600;
      font-size: 37px;
      @media (max-width: 767px) {
        text-align: center;
      }
      @media (max-width: 480px) {
        font-size: 28px;
      }
    }
  }
  .form {
    display: table;
    margin: 0 auto;

    input {
      padding: 0 10px;
      width: 240px;
      height: 37px;
      border: 1px solid $color1;
      color: $color3;
      font-size: 14px;
      line-height: 37px;
      @media (max-width: 991px) {
        width: 180px;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
  
      &.full {
        width: 100%;
      }
    }
  
    input + input {
      margin-left: 20px;
      @media (max-width: 991px) {
        margin-left: 10px;
      }
      @media (max-width: 480px) {
        margin: 10px auto 0;
      }
    }
  
    textarea {
      padding: 10px;
      min-width: 100%;
      min-height: 120px;
      max-width: 100%;
      width: 100%;
      height: 120px;
      border: 1px solid $color1;
      color: $color3;
      font-size: 14px;
      resize: none;
    }
  
    button {
      margin-top: 30px;
      width: 262px;
      height: 63px;
      background-color: $color1;
      color: #ffffff;
      text-align: center;
      font-size: 25px;
      line-height: 63px;
    }
  
    .input-row + .input-row {
      margin-top: 7px;
    }
  }

  .info {
    float: right;
    margin-left: 60px;
    max-width: 300px;
    text-align: center;
    @media (max-width: 991px) {
      margin-left: 30px;
    }
    @media (max-width: 767px) {
      position: relative;
      float: none;
      margin: 60px auto 0;
    }

    .logo {
      @include Image;
      display: block;
      margin: 0 auto;
      width: 294px;
      height: 100px;
      background-image: url('../../static/images/logo.svg');
      @media (max-width: 480px) {
        width: calc(294px * 0.7);
        height: calc(100px * 0.7);
      }
    }

    h2 {
      margin-top: 58px;
      color: $color3;
      font-weight: 600;
      font-size: 24px;

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }

    p {
      margin-top: 30px;
      color: $color3;
      font-size: 20px;

      @media (max-width: 480px) {
        font-size: 14px;
      }
      a {
        color: $color3;
      }
    }
    p + p {
      margin-top: 6px;
    }
  }
}