.sem {
  overflow: hidden;
  background-color: #72a6ad;
  color: #ffffff;

  h2 {
    @extend .offer-title;
  }

  .items {
    margin-top: 83px;
    text-align: center;
    font-size: 0;
    @media (max-width: 991px) {
      margin-top: 60px;
    }
  }
  .item {
    position: relative;
    display: inline-block;
    width: 180px;
    vertical-align: top;
    cursor: pointer;
    @media (max-width: 991px) {
      width: 120px;
    }
    @media (max-width: 700px) {
      width: 40%;
    }
    @media (max-width: 480px) {
      width: 100%;
    }

    i {
      @extend .offer-icon;
    }
    &:hover i:after {
      filter: drop-shadow(8px 8px 8px rgba(0,0,0,0.43)); 
    }
    &:hover i {
      top: -5px;
      left: -5px;
    }

    & i.sem-campaign:after {
      background-image: url('../../static/images/sem-campaign.svg');
    }
    &:hover i.sem-campaign:after {
      background-image: url('../../static/images/sem-campaign--hover.svg');
    }

    & i.sem-keyword:after {
      background-image: url('../../static/images/sem-keyword.svg');
    }
    &:hover i.sem-keyword:after {
      background-image: url('../../static/images/sem-keyword--hover.svg');
    }

    & i.sem-ab:after {
      background-image: url('../../static/images/sem-ab.svg');
    }
    &:hover i.sem-ab:after {
      background-image: url('../../static/images/sem-ab--hover.svg');
    }

    & i.sem-tracking:after {
      background-image: url('../../static/images/sem-tracking.svg');
    }
    &:hover i.sem-tracking:after {
      background-image: url('../../static/images/sem-tracking--hover.svg');
    }

    span {
      @extend .offer-icon-title;
    }
  }

  .item + .item {
    margin-left: 60px;
    @media (max-width: 700px) {
      margin-left: 0;
    }
  }

  p {
    @extend .offer-text;
  }

  .buttons {
    margin: 30px auto 40px;
    text-align: center;
  }
}