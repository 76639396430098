@import './_common';

.container {
  position: relative;
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: 375px) {
    padding: 0 10px;
  }

  @media (min-width: 768px) {
    max-width: 750px;
  }
  @media (min-width: 992px) {
    max-width: 970px;
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  }
  @media (min-width: 1300px) {
    max-width: 1245px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  min-width: 100%;

  @media (max-width: 375px) {
    margin: 0 -10px;
  }
}

.col {
  position: relative;
  padding: 0 15px;
  width: 100%;

  @media (max-width: 375px) {
    padding: 0 10px;
  }
}