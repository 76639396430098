* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
}

html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
  background-color: #ffffff;
  font-size: 14px;
  line-height: 1.42857143;
}

a {
	background-color: transparent;
}

a:active,
a:hover {
	outline: 0;
}

img {
  border: 0;
  vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	color: inherit;
	font: inherit;
}

button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
	cursor: pointer;
	-webkit-appearance: button;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0;
}

input {
	line-height: normal;
}

input[type=checkbox],
input[type=radio] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	height: auto;
}

input[type=search] {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-appearance: textfield;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
	-webkit-appearance: none;
}

textarea {
	overflow: auto;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

td,
th {
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-weight: 500;
  font-family: inherit;
  line-height: 1.1;
}

button,
input[type="submit"],
input[type="reset"],
select,
a {
  text-decoration: none;
  cursor: pointer;
}

button:hover,
button:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
select:hover,
select:focus,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}