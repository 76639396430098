.testimonials {
  position: relative;
  height: 866px;
  background-image: url('../../static/images/back-3.jpg');
  background-attachment: fixed;
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;

  color: #ffffff;
  text-align: center;
  @media (max-width: 991px) {
    height: 700px;
  }
  @media (max-width: 767px) {
    height: auto;
  }

  .container {
    position: relative;
    height: 100%;
  }
  .text {
    font-weight: 600;
    font-size: 36px;
    @media (max-width: 991px) {
      font-size: 32px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
  .name {
    margin-top: 36px;
    font-weight: 600;
    font-size: 36px;
    @media (max-width: 991px) {
      font-size: 24px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .swiper-container {
    position: absolute;
    right: 0;
    bottom: 90px;
    max-width: 420px;
    @media (max-width: 767px) {
      position: relative;
      right: auto;
      bottom: 0;
      left: auto;
      padding: 60px 0;
      max-width: 100%;
      width: 100%;
    }
  }
  .swiper-controls {
    position: relative;
    margin-top: 60px;
    width: 100%;
    height: 25px;
    vertical-align: top;
    line-height: 25px;
  }
  .swiper-button-white {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    display: inline-block;
    margin: 0 28px;
    margin-top: 0;
    width: 16px;
    height: 25px;
    background-size: contain;
    transform: none;
  }
  .swiper-pagination {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    height: 25px;
    vertical-align: top;
    line-height: 25px;
    transform: none;
  }
  .swiper-pagination-bullet {
    margin: 0 8px;
    background: #ffffff;
  }
  .swiper-pagination-bullet-active {
    background: #ffffff;
  }
}