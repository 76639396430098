@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-SemiBold.eot');
  src: url('../fonts/Sarabun-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Sarabun-SemiBold.woff2') format('woff2'),
      url('../fonts/Sarabun-SemiBold.woff') format('woff'),
      url('../fonts/Sarabun-SemiBold.ttf') format('truetype'),
      url('../fonts/Sarabun-SemiBold.svg#Sarabun-SemiBold') format('svg');
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'Sarabun';
  src: url('../fonts/Sarabun-Regular.eot');
  src: url('../fonts/Sarabun-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Sarabun-Regular.woff2') format('woff2'),
      url('../fonts/Sarabun-Regular.woff') format('woff'),
      url('../fonts/Sarabun-Regular.ttf') format('truetype'),
      url('../fonts/Sarabun-Regular.svg#Sarabun-Regular') format('svg');
}

