.numbers {
  position: relative;
  height: 493px;
  background-image: url('../../static/images/back-2.jpg');
  background-attachment: fixed;
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 480px) {
    height: 360px;
  }

  .container {
    height: 100%;
  }
  .items {
    position: absolute;
    top: 50%;
    left: 0;
    overflow: hidden;
    width: 100%;
    text-align: center;
    font-size: 0;
    transform: translateY(-50%);
    @media (max-width: 991px) {
      left: 50%;
      width: 400px;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 480px) {
      width: 260px;
    }
  }
  .item {
    display: inline-block;
    margin: 0 15px;
    width: 160px;
    color: #ffffff;
    vertical-align: top;
    @media (max-width: 991px) {
      margin-top: 10px;
    }
    @media (max-width: 480px) {
      margin: 0 5px;
      width: 120px;
    }
  }
  .number {
    display: block;
    font-weight: 600;
    font-size: 48px;
    @media (max-width: 480px) {
      font-size: 32px;
    }
  }
  .title {
    display: block;
    font-weight: 600;
    font-size: 17px;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
}