.section-we-are-ad-it-easy {
  position: relative;
  overflow: hidden;
  min-height: 540px;
  width: 100%;
  height: 100%;

  background-image: url('../../static/images/back-1.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    height: 100%;
  }

  .logo {
    position: absolute;
    top: 23px;
    left: 15px;

    @media (max-width: 767px) {
      left: 0;
      width: 100%;
      text-align: center;
    }
    i {
      @include Image;
      display: inline-block;
      width: 294px;
      height: 100px;
      background-image: url('../../static/images/logo.svg');
      @media (max-width: 767px) {
        width: calc(294px * 0.7);
        height: calc(100px * 0.7);
      }
    }
  }

  .titles {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 0 15px;
    width: 100%;
    color: #ffffff;
    vertical-align: middle;
    text-align: center;
    font-weight: 600;
    transform: translateY(-50%);
    @media (max-width: 480px) {
      top: 30%;
      transform: translateY(-30%);
    }
    h1 {
      font-size: 94px;
      @media (max-width: 767px) {
        font-size: 60px;
      }
      @media (max-width: 480px) {
        font-size: 50px;
      }
    }
    .subtitle {
      display: inline-block;
      margin-top: 20px;
      max-width: 550px;
      font-size: 18px;
      line-height: 23px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .buttons {
    position: absolute;
    bottom: 126px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0;
    @media (max-width: 767px) {
      bottom: 64px;
    }
  }
}