.what-we-can-offer {
  overflow: hidden;
  background-color: #fff;

  h2 {
    margin-top: 50px;
    color: $color3;
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    @media (max-width: 768px) {
      margin-top: 40px;
      font-size: 40px;
    }
  }

  p {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 810px;
    color: $color4;
    text-align: center;
    font-size: 18px;
    @media (max-width: 768px) {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 840px;
    list-style: none;
    @media (max-width: 991px) {
      width: calc(840px * 0.8);
    }
    @media (max-width: 767px) {
      display: block;
      width: 100%;
    }

    li {
      @extend .col;
      margin-top: 40px;
      width: 420px;
      height: 180px;
      @media (max-width: 991px) {
        width: calc(420px * 0.8);
        height: calc(180px * 0.8);
      }
      @media (max-width: 767px) {
        display: block;
        float: none;
        margin: 10px auto 0;
        width: 100%;
        height: 80px;
      }
      
      div {
        position: relative;
        height: 100%;
        background-color: #f1fafb;
        color: $color4;
        cursor: pointer;
        &:hover {
          top: -10px;
          left: -10px;
          background-color: $color5;
          box-shadow: 10px 10px 5px 0px rgba(229,229,229,1);
        }
      }
      .title {
        position: absolute;
        top: 46px;
        left: 0;
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 41px;
        line-height: 41px;
        @media (max-width: 991px) {
          top: 40px;
          font-size: 36px;
          line-height: 36px;
        }
        @media (max-width: 767px) {
          top: 10px;
        }
      }
      .text {
        position: absolute;
        bottom: 56px;
        left: 0;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 23px;
        line-height: 23px;
        @media (max-width: 991px) {
          bottom: 40px;
          font-size: 16px;
          line-height: 16px;
        }
        @media (max-width: 767px) {
          bottom: 10px;
        }
      }
      
    }
  }

  .button-1 {
    display: block;
    margin: 40px auto 50px;
  }
}